<template>
    <div v-loading="loading">
      <section>
        <article>
          <div class="container">
            <div class="blog-header text-center">
              <h1>News and Articles on Acaki Lodge</h1>
            </div>
            <hr />
  
            <div
              v-loading="loading || is_changing"
              class="blog-body table-responsive mb-5"
            >
              <ScaleOut
                v-if="isLoading && !loadingError"
                class="mt-3"
                :background="'#164B70'"
              />
              <div
                @click="getBlog"
                v-else-if="!isLoading && loadingError"
                style="cursor: pointer"
              >
                Unable to Load Blog Now. Please click here to retry
              </div>

              <div v-else>
                <div class="mt-5" v-for="post in blog" :key="post.blogID">
                  <div class="mb-3">
                    <h2>{{ post.blogTitle }}</h2>
                    <small>Added On: {{ formattedDate(post.createdAt) }} </small>
                  </div>
                  <div class="blog-img">
                    <img :src="post.blogImage" alt="">
                  </div>

                  <div class="mt-3" v-html="truncate(post.description, 1000)"></div>
                  <button class="btn btn-md moreBtn mt-3" @click="$router.push({path: `/blog/${post.blogID}`})">
                    Continue Reading
                    <i class="el-icon-right"></i>
                  </button>
                </div>
              </div>


            </div>
  
          </div>
        </article>
      </section>
  
    </div>
  </template>
  
  <script>
  import ScaleOut from "@/components/backoffice/scale-out-component.vue";
  import { format, parseISO } from "date-fns";
  export default {
    components: {
      ScaleOut,
    },
  
    data() {
      return {
        loading: false,
        isLoading: false,
        is_changing: false,
        showBlogEditDialog: false,
        blog: [],
        blog_Data: {},
        decoded_token: {},
        decoded_token2: {},
        first_name: "",
  
        tableData: [],
        tableProps: {
          border: true,
          stripe: true,
        },
  
        filters: [
          {
            prop: "fullname",
            value: "",
          },
        ],
        loadingError: false,
      };
    },
  
    computed: {
      formattedDate() {
        return (dateString) => format(parseISO(dateString), "do MMM yyyy hh:mma");
      },
    },
  
    mounted() {
      this.getBlog();
    },
  
    methods: {
      showEditblogData(blogData) {
        this.showBlogEditDialog = true;
        this.blog_Data = blogData;
      },
  
      closeEditBlogDialog() {
        this.showBlogEditDialog = false;
      },
  
      async getBlog() {
        this.isLoading = true;
        this.isLoadingError = false;
        try {
          //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
          let request = await this.$http.get(`blog`);
          if (request.data.success && request.data.message === "BLOG_FETCHED_SUCCESSFULLY") {
            this.blog = request.data.posts;
          } else throw "UNEXPECTED_RESPONSE_RECEIVED";
        } catch (error) {
          this.isLoadingError = true;
          //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
          this.$notify({
            title: "Operation Failed",
            message: "Unable to fetch blog Posts now, please try again",
            type: "error",
          });
        } finally {
          this.isLoading = false;
        }
      },
  
      async deleteBanner(blogID) {
        this.$confirm(
          // eslint-disable-line no-unreachable
          "This will permanently delete this Post. Do you want to Continue?",
          "Confirm delete",
          {
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        )
          .then(async () => {
            try {
              this.deleting = true;
              let request = await this.$http.delete(`blog/${blogID}`);
              if (
                request.data.success &&
                request.data.message === "BLOG_DELETED_SUCCESSFULLY"
              ) {
                this.getBlog();
                this.$notify({
                  title: "Success",
                  message: "Post deleted successfully!",
                  type: "success",
                });
              } else {
                throw "UNEXPECTED_RESPONSE";
              }
            } catch (error) {
              if (error.message == "Network Error") {
                this.loadingError = true;
                return this.$notify({
                  title: "Connection failed",
                  message: "A network error occurred, please try again.",
                  type: "error",
                });
              }
              this.$notify({
                title: "Unable to Delete",
                message: "Unable to delete the Post, Please try again",
                type: "error",
              });
            } finally {
              this.deleting = false;
            }
          })
          .catch(() => {
            //
          });
      },
  
  async switchStatus(activation_status, blogID) {
    try {
      this.is_changing = true;
      let request = await this.$http.patch(
        `blog/change_isActive_status/${blogID}`, {
          isActive: activation_status,
        }
      );
      if (request.data.success) {
        this.getBlog();
        this.showSuccessMessage("Success", );
            return this.$notify({
              title: "Success",
              message: "Banner status changed",
              type: "success",
            });
      } else {
        throw "UNEXPECTED_RESPONSE";
      }
    } catch (error) {
      if (error.message == "Network Error") {
        this.is_changing = false;
        return this.showFailedMessage(
          "Connection failed",
          "A network error occurred, please try again."
        );
      }
      this.loadingError = true;
      this.showFailedMessage(
        "Unable to change banner status",
        "An unexpected error occurred, please try again"
      );
    } finally {
      this.is_changing = false;
    }
  },

  

      truncate(text, length) {
        if (text.length > length) {
          return text.substring(0, length) + '...';
        }
        return text;
      },
    },
  };
  </script>
  
  <style scoped>
  .blog-header {
    padding-top: 250px;
  }
  .blog-header h1 {
    font-size: 3em;
    font-weight: 600;
  }
  
  .blog-body h2 {
    font-size: 1.8em;
    font-weight: 600;
  }
  .blog-img {
    height: 400px;
    width: 100%;
  }
  .blog-img img{
    height: 400px;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

    /*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
    @media (min-width: 481px) and (max-width: 767px) {
      .blog-header {
        padding-top: 150px;
      }
      .blog-header h1 {
        font-size: 2em;
        font-weight: 600;
      }
      
      .blog-body h2 {
        font-size: 1.5em;
        font-weight: 600;
      }
      .blog-img {
        height: 200px;
        width: 100%;
      }
      .blog-img img{
        height: 200px;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    /*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
    @media (min-width: 320px) and (max-width: 480px) {
      .blog-header {
        padding-top: 150px;
      }
      .blog-header h1 {
        font-size: 2em;
        font-weight: 600;
      }
      
      .blog-body h2 {
        font-size: 1.5em;
        font-weight: 600;
      }
      .blog-img {
        height: 200px;
        width: 100%;
      }
      .blog-img img{
        height: 200px;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  </style>