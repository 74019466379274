<template>
    <div v-loading="loading">

      <section>
        <article class="blog-header">
          <img :src="blogPost.blogImage" alt="">
          <div class="blog-title text-center">
            <h1>{{ blogPost.blogTitle }}</h1>
          </div>
        </article>
      </section>

      <section>
        <article class="blog-body">
          <div class="container mt-5">
            <div>
              <div class="mb-3">
                <h2>{{ blogPost.blogTitle }}</h2>
                <small>Added On: {{ formattedDate(blogPost.createdAt) }} </small>
              </div>
              <div class="mt-3" v-html="blogPost.description"></div>
            </div>
          </div>
        </article>
      </section>
  
    </div>
  </template>
  
  <script>
  // import ScaleOut from "@/components/backoffice/scale-out-component.vue";
  import { format, parseISO } from "date-fns";
  export default {
    components: {
      // ScaleOut,
    },
  
    data() {
      return {
        loading: false,
        isLoading: false,
        is_changing: false,
        showBlogEditDialog: false,
        blogPost: {},
        blog_Data: {},
        decoded_token: {},
        decoded_token2: {},
        first_name: "",
  
        tableData: [],
        tableProps: {
          border: true,
          stripe: true,
        },
  
        filters: [
          {
            prop: "fullname",
            value: "",
          },
        ],
        loadingError: false,
      };
    },
  
    computed: {
      formattedDate() {
        return (dateString) => format(parseISO(dateString), "do MMM yyyy hh:mma");
      },
    },
  
    mounted() {
      this.getBlog();
    },
  
    methods: {
      showEditblogData(blogData) {
        this.showBlogEditDialog = true;
        this.blog_Data = blogData;
      },
  
      closeEditBlogDialog() {
        this.showBlogEditDialog = false;
      },
  
      async getBlog() {
        this.isLoading = true;
        this.isLoadingError = false;
        try {
          //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
          let request = await this.$http.get(`blog/${this.$route.params.blogID}`);
          if (request.data.success && request.data.message === "BLOG_FETCHED_SUCCESSFULLY") {
            this.blogPost = request.data.post;
          } else throw "UNEXPECTED_RESPONSE_RECEIVED";
        } catch (error) {
          this.isLoadingError = true;
          //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
          this.$notify({
            title: "Operation Failed",
            message: "Unable to fetch blog Posts now, please try again",
            type: "error",
          });
        } finally {
          this.isLoading = false;
        }
      },
  
      async deleteBanner(blogID) {
        this.$confirm(
          // eslint-disable-line no-unreachable
          "This will permanently delete this Post. Do you want to Continue?",
          "Confirm delete",
          {
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        )
          .then(async () => {
            try {
              this.deleting = true;
              let request = await this.$http.delete(`blog/${blogID}`);
              if (
                request.data.success &&
                request.data.message === "BLOG_DELETED_SUCCESSFULLY"
              ) {
                this.getBlog();
                this.$notify({
                  title: "Success",
                  message: "Post deleted successfully!",
                  type: "success",
                });
              } else {
                throw "UNEXPECTED_RESPONSE";
              }
            } catch (error) {
              if (error.message == "Network Error") {
                this.loadingError = true;
                return this.$notify({
                  title: "Connection failed",
                  message: "A network error occurred, please try again.",
                  type: "error",
                });
              }
              this.$notify({
                title: "Unable to Delete",
                message: "Unable to delete the Post, Please try again",
                type: "error",
              });
            } finally {
              this.deleting = false;
            }
          })
          .catch(() => {
            //
          });
      },
  
  async switchStatus(activation_status, blogID) {
    try {
      this.is_changing = true;
      let request = await this.$http.patch(
        `blog/change_isActive_status/${blogID}`, {
          isActive: activation_status,
        }
      );
      if (request.data.success) {
        this.getBlog();
        this.showSuccessMessage("Success", );
            return this.$notify({
              title: "Success",
              message: "Banner status changed",
              type: "success",
            });
      } else {
        throw "UNEXPECTED_RESPONSE";
      }
    } catch (error) {
      if (error.message == "Network Error") {
        this.is_changing = false;
        return this.showFailedMessage(
          "Connection failed",
          "A network error occurred, please try again."
        );
      }
      this.loadingError = true;
      this.showFailedMessage(
        "Unable to change banner status",
        "An unexpected error occurred, please try again"
      );
    } finally {
      this.is_changing = false;
    }
  },
    },
  };
  </script>

  <style scoped>
    .blog-header {
      position: relative;
    }
    .blog-header img{
      width: 100%;
      height: 70vh;
      object-fit: cover;
      object-position: center;
    }
    .blog-title {
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      position: absolute;
      top: 0px;
      bottom: 0px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 100px;
    }

    .blog-body {
      min-height: 100vh;
      padding-top: 50px;
      padding-bottom: 50px;
    }

    
  .blog-header h1 {
    font-size: 3em;
    font-weight: 600;
  }
  
  .blog-body h2 {
    font-size: 1.8em;
    font-weight: 600;
  }

  

    /*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
    @media (min-width: 481px) and (max-width: 767px) {
      .blog-header h1 {
        font-size: 2em;
        font-weight: 600;
      }
      
      .blog-body h2 {
        font-size: 1.5em;
        font-weight: 600;
      }
    }

    /*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
    @media (min-width: 320px) and (max-width: 480px) {
      .blog-header h1 {
        font-size: 2em;
        font-weight: 600;
      }
      
      .blog-body h2 {
        font-size: 1.5em;
        font-weight: 600;
      }
    }
  </style>